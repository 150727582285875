import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    order: null,
    orders: [],
    debt: [],
    campaigns: [],
    customers: [],
    promotionCode: '',
    promotionDes: '',
    promotionX1Code: '',
    promotionX1Des: '',
    search: ''
  },
  getters: {
    getOrderByStatus: state => status => {
      return state.orders.filter(order => status.includes(order.status));
    }
  },
  actions: {
    getCusByManager({ commit, state }, { key, value }) {
      return new Promise((resolve, reject) => {
        commit('setCustomers', []);
        let $filter = '';
        if (
          !Vue.prototype.$_.isEmpty(key) &&
          !Vue.prototype.$_.isEmpty(value)
        ) {
          $filter += `${key} eq '${value}'`;
        }
        if (!Vue.prototype.$_.isEmpty(state.search)) {
          $filter += `and search eq '${state.search}'`;
        }
        Vue.prototype.$http
          .get('/getCusByManager', {
            params: {
              $filter
            }
          })
          .then(
            response => {
              commit('setCustomers', response.data.d.results);
              resolve(response);
            },
            error => {
              reject(error);
            }
          );
      });
    },
    getOrders({ commit }, { customerCode }) {
      return new Promise((resolve, reject) => {
        commit('setOrders', []);
        let $filter = '';
        if (!Vue.prototype.$_.isEmpty(customerCode)) {
          $filter += `customerCode eq '${customerCode}'`;
        }
        Vue.prototype.$http
          .get('/orders', {
            params: {
              $filter
            }
          })
          .then(
            response => {
              commit('setOrders', response.data.d.results);
              resolve(response);
            },
            error => {
              reject(error);
            }
          );
      });
    },
    getOrder({ commit }, { orderID }) {
      return new Promise((resolve, reject) => {
        commit('setOrder', {});
        Vue.prototype.$http
          .post('/orders', {
            code: 'SELECT',
            orderID,
            orderLines: []
          })
          .then(
            response => {
              commit('setOrder', response.data.d);
              resolve(response);
            },
            error => {
              reject(error);
            }
          );
      });
    },
    getDebt({ commit }, { businessPartnerID }) {
      return new Promise((resolve, reject) => {
        commit('setDebt', []);
        let $filter = '';
        if (!Vue.prototype.$_.isEmpty(businessPartnerID)) {
          $filter += `businessPartnerID eq '${businessPartnerID}'`;
        }
        Vue.prototype.$http
          .get('/getDebt', {
            params: {
              $filter
            }
          })
          .then(
            response => {
              commit('setDebt', response.data.d.results);
              resolve(response);
            },
            error => {
              reject(error);
            }
          );
      });
    },
    getCampaigns({ commit }, { customerID }) {
      return new Promise((resolve, reject) => {
        commit('setCampaigns', []);
        let $filter = '';
        if (!Vue.prototype.$_.isEmpty(customerID)) {
          $filter += `customerID eq '${customerID}'`;
        }
        Vue.prototype.$http
          .get('/getCampaigns', {
            params: {
              $filter
            }
          })
          .then(
            response => {
              commit('setCampaigns', response.data.d.results);
              resolve(response);
            },
            error => {
              reject(error);
            }
          );
      });
    },
    getCusPromotion({ rootState, rootGetters, commit }) {
      return new Promise((resolve, reject) => {
        if(!rootState.auth.user.token_id) {
          return reject(new Error('token undefined!'));
        }
        if(!rootGetters["cart/cusPromotionLines"]) {
          return reject(new Error('cusPromotionLines undefined!'));
        }
        Vue.prototype.$http
          .post('/getPromotionCus', {
            token_id: rootGetters['auth/getToken'],
            partnrType: rootState.auth.user?.partnrType?.toString(),
            lines: rootGetters["cart/cusPromotionLines"]
          })
          .then(
            response => {
              commit('setPromotionCode', response.data.d.promotionCode);
              commit('setPromotionDes', response.data.d.promotionDes);
              commit('setPromotionX1Code', response.data.d.promotionX1Code);
              commit('setPromotionX1Des', response.data.d.promotionX1Des);
              resolve(response);
            },
            error => {
              reject(error);
            }
          );
      });
    }
  },
  mutations: {
    setSearch: (state, payload) => {
      state.search = payload;
    },
    setCustomers: (state, payload) => {
      state.customers = payload;
    },
    setOrders: (state, payload) => {
      state.orders = payload;
    },
    setOrder: (state, payload) => {
      state.order = payload;
    },
    setDebt: (state, payload) => {
      state.debt = payload;
    },
    setCampaigns: (state, payload) => {
      state.campaigns = payload;
    },
    setPromotionCode: (state, payload) => {
      state.promotionCode = payload;
    },
    setPromotionDes: (state, payload) => {
      state.promotionDes = payload;
    },
    setPromotionX1Code: (state, payload) => {
      state.promotionX1Code = payload;
    },
    setPromotionX1Des: (state, payload) => {
      state.promotionX1Des = payload;
    },
  }
};
