import Vue from 'vue'
import bcrypt from 'bcryptjs'
import DeviceDetector from 'device-detector-js'

export default {
  namespaced: true,
  state: {
    user: JSON.parse(localStorage.getItem('user')) || {},
    customerSelectedLocal: {},
    customerSelected: JSON.parse(localStorage.getItem('customerSelected')) || {}
  },
  getters: {
    isFirstLogin: state => state.user.isFirst,
    isAuthenticated: state => !Vue.prototype.$_.isEmpty(state.user),
    authStatus: state => state.status,
    getGhtc: state => {
      if (state.user.partnrType === 0) {
        return state.user.ghtc
      } else {
        return state.customerSelected.ghtc || 0
      }
    },
    getDebt: state => {
      if (state.user.partnrType === 0) {
        return state.user.debt
      } else {
        return state.customerSelected.debt || 0
      }
    },
    getCreditLimit: state => {
      if (state.user.partnrType === 0) {
        return state.user.creditLimit
      } else {
        return state.customerSelected.creditLimit || 0
      }
    },
    getDeliveryAddress: state => {
      if (state.user.partnrType === 0) {
        return state.user.address
      } else {
        return state.customerSelected.address || ''
      }
    },
    getTdvCode: state => {
      if (state.user.partnrType === 0) {
        return state.user.tdv_token
      } else {
        return state.user.token_id
      }
    },
    getToken: state => {
      if (state.user.partnrType === 0) {
        return state.user.token_id
      } else {
        return state.customerSelected.token_id || ''
      }
    },
    customerIsSelected: state => {
      if (state.user.partnrType === 0) {
        return true
      } else {
        return !Vue.prototype.$_.isEmpty(state.customerSelected.token_id)
      }
    },
    getDeviceId: function () {
      const deviceDetector = new DeviceDetector()
      const userAgent = navigator.userAgent
      const device = deviceDetector.parse(userAgent)
      const deviceId = `WEB_${device.os.name}_${device.os.version}_${device.client.name}_${device.client.version}`
      return deviceId
    }
  },
  actions: {
    updateLoginFirst ({ commit, state }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.post('/update_login_first', {
          token_id: state.user.token_id,
          isFirst: true
        })
          .then(response => {
            commit('setIsFirstLogin', false)
            resolve(response)
          }, error => {
            reject(error)
          })
      })
    },
    logIn ({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('setUser', {})
        Vue.prototype.$http.post('/login', user)
          .then(response => {
            const salt = bcrypt.genSaltSync(10)
            const hash = bcrypt.hashSync(user.password, salt)
            response.data.d.password = hash
            commit('setUser', response.data.d)
            resolve(response)
          }, error => {
            reject(error)
          })
      })
    },
    register ({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('setUser', {})
        Vue.prototype.$http.post('/register', user)
          .then(response => {
            resolve(response)
          }, error => {
            reject(error)
          })
      })
    },
    changePassword ({ state, commit }, paypload) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.post('/changePass', { ...paypload, token_id: state.user.token_id })
          .then(response => {
            const salt = bcrypt.genSaltSync(10)
            const hash = bcrypt.hashSync(paypload.password, salt)
            commit('updateUserPassword', hash)
            resolve(response)
          }, error => {
            reject(error)
          })
      })
    },
    logOut ({ state, getters }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.post('logout', { token_id: state.user.token_id, deviceID: getters.getDeviceId })
          .then(response => {
            localStorage.clear()
            location.reload()
            resolve(response)
          }, error => {
            console.log(error)
            reject(error)
          })
      })
    }
  },
  mutations: {
    setIsFirstLogin: (state, payload) => {
      state.user.isFirst = payload
      localStorage.setItem('user', JSON.stringify(state.user))
    },
    updateUserPassword: (state, payload) => {
      state.user.password = payload
      localStorage.setItem('user', JSON.stringify(state.user))
    },
    setUser: (state, payload) => {
      state.user = payload
      localStorage.setItem('user', JSON.stringify(state.user))
    },
    setCustomerSelectedLocal: (state, payload) => {
      state.customerSelectedLocal = payload
    },
    setCustomerSelected: (state, payload) => {
      state.customerSelected = payload
      localStorage.setItem('customerSelected', JSON.stringify(state.customerSelected))
    }
  }
}
