import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    notifications: [],
    notificationCount: 0,
    pagination: {
      currentPage: 1,
      pageSize: 5,
      total: 0
    }
  },
  getters: {},
  actions: {
    getNotificationCount ({ commit, state, rootState }) {
      return new Promise((resolve, reject) => {
        let $filter = ''

        if (!Vue.prototype.$_.isEmpty(rootState.auth.user.token_id)) {
          $filter += `username eq '${rootState.auth.user.token_id}'`
        } else {
          return
        }
        Vue.prototype.$http.get('/getNotification', {
          params: {
            $filter
          }
        }).then(response => {
          commit('setNotificationCount', response.data.d.results.filter(notify => !notify.isRead).length)
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    },
    getTotalNotifications ({ commit, state, rootState }) {
      return new Promise((resolve, reject) => {
        let $filter = ''

        if (!Vue.prototype.$_.isEmpty(rootState.auth.user.token_id)) {
          $filter += `username eq '${rootState.auth.user.token_id}'`
        } else {
          return
        }
        Vue.prototype.$http.get('/getNotification', {
          params: {
            $filter
          }
        }).then(response => {
          if (state.pagination.total !== response.data.d.results.length) {
            commit('setPaginationTotal', response.data.d.results.length)
          }
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    },
    readNotification ({ commit, rootState, dispatch }, { id }) {
      return new Promise((resolve, reject) => {
        let $filter = ''

        if (!Vue.prototype.$_.isEmpty(rootState.auth.user.token_id)) {
          $filter += `username eq '${rootState.auth.user.token_id}'`
        } else {
          return
        }

        Vue.prototype.$http.post('/readNotification', { id }, {
          $filter
        }).then(response => {
          dispatch('getNotificationCount')
          commit('readNotificationLocal', id)
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    },
    getNotification ({ commit, state, rootState }) {
      return new Promise((resolve, reject) => {
        let $filter = ''
        if (!Vue.prototype.$_.isEmpty(rootState.auth.user.token_id)) {
          $filter += `username eq '${rootState.auth.user.token_id}'`
        }
        Vue.prototype.$http.get('/getNotification', {
          params: {
            $filter,
            $skip: state.pagination.currentPage - 1,
            $top: state.pagination.pageSize
          }
        })
          .then(response => {
            commit('setNotifications', [...state.notifications, ...response.data.d.results])
            resolve(response)
          }, error => {
            reject(error)
          })
      })
    }
  },
  mutations: {
    readNotificationLocal: (state, payload) => {
      state.notifications.find(notify => notify.id === payload).isRead = true
    },
    setNotificationCount: (state, payload) => {
      state.notificationCount = payload
    },
    // Pagination
    setPagination: (state, payload) => {
      state.pagination = payload
    },
    setPaginationPageSize: (state, payload) => {
      state.pagination.pageSize = payload
    },
    setPaginationCurrentPage: (state, payload) => {
      state.pagination.currentPage = payload
    },
    setPaginationTotal: (state, payload) => {
      state.pagination.total = payload
    },
    setNotifications: (state, payload) => {
      state.notifications = payload
    }
  }
}
