import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    promotions: [],
    promotionsCart: [],
    sortOptionSelected: '',
    sortOption: [
      {
        value: '',
        label: 'Tất cả'
      },
      {
        value: 'HD',
        label: 'CT Hợp đồng'
      },
      {
        value: 'LE',
        label: 'CT Lẻ'
      }
    ],
    pagination: {
      currentPage: 1,
      pageSize: 10,
      total: 0
    }
  },
  getters: {
    getCurrentPromotion: state => payload => {
      return state.promotions.find(promotion => promotion.promotionCode === payload)
    }
  },
  actions: {
    getTotalPromotions ({ commit, state }) {
      return new Promise((resolve, reject) => {
        let $filter = ''

        if (!Vue.prototype.$_.isEmpty(state.sortOptionSelected)) {
          $filter += `promotionType eq '${state.sortOptionSelected}'`
        }

        Vue.prototype.$http.get('/getPromotions', {
          params: {
            $filter
          }
        }).then(response => {
          if (state.pagination.total !== response.data.d.results.length) {
            commit('setPaginationTotal', response.data.d.results.length)
          }
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    },
    getPromotions ({ commit, state, dispatch }) {
      commit('setPromotions', [])
      return new Promise((resolve, reject) => {
        let $filter = ''

        if (!Vue.prototype.$_.isEmpty(state.sortOptionSelected)) {
          $filter += `promotionType eq '${state.sortOptionSelected}'`
        }

        Vue.prototype.$http.get('/getPromotions', {
          params: {
            $filter,
            $skip: state.pagination.currentPage - 1,
            $top: state.pagination.pageSize
          }
        }).then(response => {
          commit('setPromotions', response.data.d.results)

          // After set promotion list we will call action to update total of pagination
          dispatch('getTotalPromotions')

          resolve(response)
        }, error => {
          reject(error)
        })
      })
    }
  },
  mutations: {
    // Pagination
    setPagination: (state, payload) => {
      state.pagination = payload
    },
    setPaginationPageSize: (state, payload) => {
      state.pagination.pageSize = payload
    },
    setPaginationCurrentPage: (state, payload) => {
      state.pagination.currentPage = payload
    },
    setPaginationTotal: (state, payload) => {
      state.pagination.total = payload
    },

    setSortOptionSelected: (state, payload) => {
      state.sortOptionSelected = payload
    },
    setPromotions: (state, payload) => {
      state.promotions = payload
    },
    setPromotionsCart: (state, payload) => {
      state.promotionsCart = payload
    }
  }
}
