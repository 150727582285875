import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import promotion from './modules/promotion'
import product from './modules/product'
import user from './modules/user'
import cart from './modules/cart'
import customer from './modules/customer'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    headerMobile: {
      heading: false,
      headingText: '',
      back: false,
      search: true,
      notify: true,
      cart: true
    },
    footerMobile: true,
    banners: []
  },
  mutations: {
    setFooterMobile: (state, payload) => {
      state.footerMobile = payload
    },
    setHeaderMobile: (state, payload) => {
      state.headerMobile = payload
    },
    setBanners: (state, payload) => {
      state.banners = payload
    }
  },
  actions: {
    setFooterMobile ({ commit }, payload) {
      commit('setFooterMobile', payload)
    },
    setHeaderMobile ({ commit }, payload) {
      commit('setHeaderMobile', payload)
    },
    getBanner ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('setBanners', [])
        Vue.prototype.$http.get('/getBanner')
          .then(response => {
            commit('setBanners', response.data.d.results)
            resolve(response)
          }, error => {
            reject(error)
          })
      })
    }
  },
  modules: {
    auth,
    promotion,
    product,
    user,
    cart,
    customer
  }
})
