import Vue from 'vue'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    cartItems: JSON.parse(localStorage.getItem('cartItems')) || [],
    cartSelectedItems: JSON.parse(localStorage.getItem('cartSelectedItems')) || [],
    deliveryAddress: JSON.parse(localStorage.getItem('deliveryAddress')) || '',
    deliveryDate: JSON.parse(localStorage.getItem('deliveryDate')) || moment().format('YYYYMMDD'),
    note: JSON.parse(localStorage.getItem('note')) || '',
    ctkm: [],
    promotionType: JSON.parse(localStorage.getItem('promotionType')) || 'HD',
    suggestProductList: [],
    saleDeal: JSON.parse(localStorage.getItem('saleDeal')) || {}
  },
  getters: {
    checkoutProductItems: state => {
      return state.cartItems.filter(cartItem => state.cartSelectedItems.includes(cartItem.productCode))
    },
    cusPromotionLines: state => {
      return state.cartItems.filter(cartItem => state.cartSelectedItems.includes(cartItem.productCode)).map((cartItem) => {
        return {
          productCode: cartItem.productCode,
          quantity: cartItem.quantity.toString(),
          amount: (parseFloat(cartItem.quantity) * parseFloat(cartItem.price)).toString(),
        }
      });
    },
    discountAmount: () => {
      return 0
    },
    amountBeforetax: state => {
      return state.cartItems.reduce((total, cartItem) => {
        return state.cartSelectedItems.includes(cartItem.productCode) ? total + (parseInt(cartItem.price) * parseInt(cartItem.quantity)) : total
      }, 0)
    },
    taxAmount: state => {
      return state.cartItems.reduce((total, cartItem) => {
        return state.cartSelectedItems.includes(cartItem.productCode) ? total + (parseInt(cartItem.price) * parseInt(cartItem.quantity) * (parseInt(cartItem.tax) / 100)) : total
      }, 0)
    },
    amountAfterTax: state => {
      const amountBeforetax = state.cartItems.reduce((total, cartItem) => {
        return state.cartSelectedItems.includes(cartItem.productCode) ? total + (parseInt(cartItem.price) * parseInt(cartItem.quantity)) : total
      }, 0)
      const taxAmount = state.cartItems.reduce((total, cartItem) => {
        return state.cartSelectedItems.includes(cartItem.productCode) ? total + (parseInt(cartItem.price) * parseInt(cartItem.quantity) * (parseInt(cartItem.tax) / 100)) : total
      }, 0)
      return amountBeforetax + taxAmount
    },
    cartCount: state => {
      return state.cartItems.length
    }
  },
  actions: {
    setDefaultDeliveryDate ({ commit, rootGetters }) {
      commit('setDeliveryDate', moment(Date.now() + 3600 * 1000 * parseInt(rootGetters['auth/getGhtc'])).format('YYYYMMDD'))
    },
    toggleCartSelectedItems ({ commit }, payload) {
      commit('toggleCartSelectedItems', payload)
    },
    setPromotionType ({ commit }, payload) {
      commit('setPromotionType', payload)
    },
    getPromotionCart ({ commit, state, rootGetters }) {
      return new Promise((resolve, reject) => {
        commit('setSuggestProductList', [])
        let $filter = ''
        if (!Vue.prototype.$_.isEmpty(rootGetters['auth/getToken'])) {
          $filter += `customerID eq '${rootGetters['auth/getToken']}'`
        }
        if (!Vue.prototype.$_.isEmpty(state.promotionType)) {
          $filter += `and promotionType eq '${state.promotionType}'`
        }
        Vue.prototype.$http.get('/getPromotionCart', {
          params: {
            $filter
          }
        })
          .then(response => {
            commit('setCtkm', response.data.d.results)
            resolve(response)
          }, error => {
            reject(error)
          })
      })
    },
    checkDeliveryAddress ({ commit, state, rootGetters }) {
      if (Vue.prototype.$_.isEmpty(state.deliveryAddress)) {
        commit('setDeliveryAddress', rootGetters['auth/getDeliveryAddress'])
      }
    },
    getSuggestProduct ({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        commit('setSuggestProductList', [])
        let $filter = ''
        if (!Vue.prototype.$_.isEmpty(rootState.auth.user.token_id)) {
          $filter += `username eq '${rootState.auth.user.token_id}'`
        }
        if (!Vue.prototype.$_.isEmpty(rootState.product.groupCode)) {
          $filter += `and groupCode eq '${rootState.product.groupCode}'`
        }
        Vue.prototype.$http.get('/getProducts', {
          params: {
            $filter,
            $skip: 0,
            $top: 5
          }
        })
          .then(response => {
            commit('setSuggestProductList', response.data.d.results)
            resolve(response)
          }, error => {
            reject(error)
          })
      })
    },
    postOrders ({ commit, getters, state, rootGetters, rootState }) {
      return new Promise((resolve, reject) => {
        const data = {
          code: 'INSERT',
          customerCode: rootGetters['auth/getToken'],
          tdvCode: rootGetters['auth/getTdvCode'],
          createdBy: rootState.auth.user.username,
          deliveryAddress: state.deliveryAddress,
          deliveryDate: state.deliveryDate,
          taxAmount: getters.taxAmount.toString(),
          amountBeforetax: getters.amountBeforetax.toString(),
          amountAfterTax: getters.amountAfterTax.toString(),
          discountAmount: getters.discountAmount.toString(),
          saleDeal: state.saleDeal?.value?.toString() || '',
          saleDealCus: rootState.customer.promotionCode,
          zweb: "X",
          note: state.note,
          orderLines: state.cartItems.reduce((orders, order) => {
            if (state.cartSelectedItems.includes(order.productCode)) {
              orders.push({
                productCode: order.productCode,
                unit: order.unit,
                price: order.price.toString(),
                quantity: order.quantity.toString()
              })
            }
            return orders
          }, [])
        }
        Vue.prototype.$http.post('/orders', data)
          .then(response => {
            commit('setCartItems', [])
            commit('setCartSelectedItems', [])
            commit('setDeliveryDate', moment(Date.now() + 3600 * 1000 * parseInt(rootGetters['auth/getGhtc'])).format('YYYYMMDD'))
            commit('setCtkm', [])
            commit('setSaleDeal', {})
            commit('setDeliveryAddress', '')
            commit('setNote', '')
            resolve(response)
          }, error => {
            reject(error)
          })
      })
    },
    addItem ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('addItemCart', payload)
        resolve()
      })
    },
    removeItem ({ commit }, { productCode }) {
      return new Promise((resolve, reject) => {
        commit('removeItemCart', { productCode })
        commit('removeItemCartSelected', { productCode })
        resolve()
      })
    }
  },
  mutations: {
    setDeliveryAddress (state, payload) {
      state.deliveryAddress = payload
      localStorage.setItem('deliveryAddress', JSON.stringify(state.deliveryAddress))
    },
    setSaleDeal (state, payload) {
      state.saleDeal = payload
      localStorage.setItem('saleDeal', JSON.stringify(state.saleDeal))
    },
    setCartSelectedItems (state, payload) {
      state.cartSelectedItems = payload
      localStorage.setItem('cartSelectedItems', JSON.stringify(state.cartSelectedItems))
    },
    updateCartItemsLocalStore (state) {
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
    },
    setCartItems (state, payload) {
      state.cartItems = payload
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
    },
    addItemCart (state, payload) {
      const product = { ...payload }
      const found = state.cartItems.find(cartItem => cartItem.productCode === product.productCode)
      if (found) {
        found.quantity++
      } else {
        if (Vue.prototype.$_.isEmpty(product.quantity)) {
          product.quantity = 1
        }
        state.cartItems.push(product)
      }
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
    },
    removeItemCart (state, { productCode }) {
      const index = state.cartItems.findIndex(cartItem => cartItem.productCode === productCode)
      if (index > -1) {
        state.cartItems.splice(index, 1)
      }
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
    },
    removeItemCartSelected (state, { productCode }) {
      const index = state.cartSelectedItems.findIndex(cartItem => cartItem.productCode === productCode)
      if (index > -1) {
        state.cartSelectedItems.splice(index, 1)
      }
      localStorage.setItem('cartSelectedItems', JSON.stringify(state.cartSelectedItems))
    },
    setSuggestProductList (state, payload) {
      state.suggestProductList = payload
    },
    setDeliveryDate (state, payload) {
      state.deliveryDate = payload
      localStorage.setItem('deliveryDate', JSON.stringify(state.deliveryDate))
    },
    setCtkm: (state, payload) => {
      state.ctkm = payload
    },
    setPromotionType: (state, payload) => {
      state.promotionType = payload
      localStorage.setItem('promotionType', JSON.stringify(state.promotionType))
    },
    setNote: (state, payload) => {
      state.note = payload
      localStorage.setItem('note', JSON.stringify(state.note))
    }
  }
}
