import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { Notification } from 'element-ui'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Categories'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/auth/Register')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/Login')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/auth/ForgotPassword')
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: () => import('@/views/auth/ChangePassword'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/change-password-front',
    name: 'ChangePasswordFront',
    component: () => import('@/views/auth/ChangePasswordFront'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/change-password-success',
    name: 'ChangePasswordSuccess',
    component: () => import('@/views/auth/ChangePasswordSuccess'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('@/views/Cart'),
    meta: {
      requiresAuth: true,
      requiresSelectCustomer: true
    }
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import('@/views/Checkout'),
    meta: {
      requiresAuth: true,
      requiresSelectCustomer: true
    }
  },
  {
    path: '/confirm-checkout/:orderID',
    name: 'ConfirmCheckout',
    component: () => import('@/views/ConfirmCheckout'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/order-history',
    component: () => import('@/views/OrderHistory'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/order-history/:orderID',
    component: () => import('@/views/OrderHistoryDetail'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pharmacy-information',
    name: 'PharmacyInformation',
    component: () => import('@/views/PharmacyInformation')
  },
  // {
  //   path: '/product',
  //   name: 'Products',
  //   component: () => import('@/views/Products'),
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/Search'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/category',
    name: 'Categories',
    component: () => import('@/views/Categories'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/category/:groupCode',
    name: 'Category',
    component: () => import('@/views/ProductWithGroupCode'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/promotion',
    name: 'Promotions',
    component: () => import('@/views/Promotions'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/promotion/:promotionCode',
    component: () => import('@/views/PromotionDetail'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/report',
    component: () => import('@/views/Report'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/customer',
    component: () => import('@/views/Customer'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/notification',
    name: 'Notification',
    component: () => import('@/views/Notification'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '*',
    name: 'Page404',
    component: () => import('@/views/Page404')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// Check router param requiresAuth if not login redirect to login page
router.beforeEach((to, from, next) => {
  // Check login for router = login
  if ((to.name === 'Login' && store.getters['auth/isAuthenticated']) || (to.name === 'ChangePassword' && !store.getters['auth/isFirstLogin'])) {
    next('/')
  } else {
    // Check Login for route has requiresAuth
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!store.getters['auth/isAuthenticated'] && to.name !== 'Login') {
        next('/login')
      } else {
        // Check is first login time
        if (to.name !== 'ChangePassword' && store.getters['auth/isFirstLogin']) {
          next('/change-password')
        } else if (to.matched.some(record => record.meta.requiresSelectCustomer)) {
          if (!store.getters['auth/customerIsSelected']) {
            Notification.error({
              duration: 1000,
              title: 'Thông báo!',
              message: 'Vui lòng chọn khách hàng'
            })
            next('/')
          } else {
            next()
          }
        } else {
          next()
        }
      }
    } else {
      next()
    }
  }
})

export default router
