import axios from 'axios'

export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  auth: {
    username: process.env.VUE_APP_API_AUTH_USERNAME,
    password: process.env.VUE_APP_API_AUTH_PASSWORD
  },
  headers: {
    'Access-Control-Allow-Origin': '*',
    'X-Requested-With': 'X',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, Access-Control-Allow-Headers, Authorization, X-Requested-With'
  }
})
