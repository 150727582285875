import Vue from 'vue'
import moment from 'moment'
import _ from 'lodash'
import Element from 'element-ui'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import router from './router'
import store from './store'
import { HTTP } from './http-common'
import enLocaleEl from 'element-ui/lib/locale/lang/en'
import viLocaleEl from 'element-ui/lib/locale/lang/vi'
import enLocale from './lang/en'
import viLocale from './lang/vi'

import './scss/element-variables.scss'
import './scss/style.scss'

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocaleEl,
    ...enLocale // Or use `Object.assign({ message: 'hello' }, enLocale)`
  },
  vi: {
    ...viLocaleEl,
    ...viLocale // Or use `Object.assign({ message: 'xin chào' }, viLocale)`
  }
}
const i18n = new VueI18n({
  locale: 'vi', // set locale
  messages // set locale messages
})

Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
})

// Settup momen js
Vue.prototype.$moment = moment

// Settup Lodash
Vue.prototype.$_ = _

// Settup axios
Vue.prototype.$http = HTTP
// If any of the API gets 401 status code this method calls auth user method to renew user and redirect to login page
// Vue.prototype.$http.interceptors.response.use(undefined, error => {
//   if (error) {
//     const originalRequest = error.config
//     if (error.response && error.response.status === 401 && originalRequest && !originalRequest._retry) {
//       originalRequest._retry = true
//       this.$store.dispatch('auth/logOut')
//       this.$router.push('/login')
//     }
//   }
// })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
