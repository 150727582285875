import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    groupProduct: [],
    groupCode: '',
    products: [],
    productsSearch: [],
    searchKey: '',
    sortOption: [
      {
        value: 'ASC',
        label: 'Thấp đến cao'
      },
      {
        value: 'DESC',
        label: 'Cao đến thấp'
      }
    ],
    sortOptionSelected: 'ASC',
    pagination: {
      currentPage: 1,
      pageSize: 10,
      total: 0
    },
    paginationSearch: {
      currentPage: 1,
      pageSize: 5,
      total: 0
    }
  },
  actions: {
    getTotalProducts ({ commit, state, rootState }) {
      return new Promise((resolve, reject) => {
        let $filter = ''

        // Check filter token id (require)
        if (!Vue.prototype.$_.isEmpty(rootState.auth.user.token_id)) {
          $filter += `username eq '${rootState.auth.user.token_id}'`
        } else {
          return
        }

        // Check filter product groupCode (option)
        if (!Vue.prototype.$_.isEmpty(state.groupCode) && state.groupCode !== '01') {
          $filter += `and groupCode eq '${state.groupCode}'`
        }

        if (!Vue.prototype.$_.isEmpty(state.searchKey)) {
          $filter += `and searchKey eq '${state.searchKey}'`
        }

        // Call API
        Vue.prototype.$http.get('/getProducts', {
          params: {
            $filter
          }
        }).then(response => {
          if (state.pagination.total !== response.data.d.results.length) {
            commit('setPaginationTotal', response.data.d.results.length)
          }
          resolve(response)
        }, error => {
          commit('setPaginationTotal', 0)
          reject(error)
        })
      })
    },
    getTotalProductsSearch ({ commit, state, rootState }) {
      return new Promise((resolve, reject) => {
        let $filter = ''

        // Check filter token id (require)
        if (!Vue.prototype.$_.isEmpty(rootState.auth.user.token_id)) {
          $filter += `username eq '${rootState.auth.user.token_id}'`
        } else {
          return
        }

        if (!Vue.prototype.$_.isEmpty(state.searchKey)) {
          $filter += `and searchKey eq '${state.searchKey}'`
        } else {
          $filter += 'and searchKey eq \'\''
        }

        // Call API
        Vue.prototype.$http.get('/getProducts', {
          params: {
            $filter
          }
        }).then(response => {
          if (state.paginationSearch.total !== response.data.d.results.length) {
            commit('setPaginationSearchTotal', response.data.d.results.length)
          }
          resolve(response)
        }, error => {
          commit('setPaginationSearchTotal', 0)
          reject(error)
        })
      })
    },
    getProducts ({ commit, state, rootState, dispatch }) {
      return new Promise((resolve, reject) => {
        // The firt set product list is empty
        commit('setProducts', [])

        let $filter = ''

        // Check filter token id (require)
        if (!Vue.prototype.$_.isEmpty(rootState.auth.user.token_id)) {
          $filter += `username eq '${rootState.auth.user.token_id}'`
        } else {
          return
        }

        // Check filter product groupCode (option)
        if (!Vue.prototype.$_.isEmpty(state.groupCode) && state.groupCode !== '01') {
          $filter += `and groupCode eq '${state.groupCode}'`
        }

        let $orderby = ''

        // Check sort price (option)
        if (!Vue.prototype.$_.isEmpty(state.sortOptionSelected)) {
          $orderby += `price '${state.sortOptionSelected}'`
        }

        // Call API
        Vue.prototype.$http.get('/getProducts', {
          params: {
            $filter,
            $orderby,
            $skip: state.pagination.currentPage - 1,
            $top: state.pagination.pageSize
          }
        }).then(response => {
          // Set product list by results
          commit('setProducts', response.data.d.results)

          // After set product list we will call action to update total of pagination
          dispatch('getTotalProducts')

          resolve(response)
        }, error => {
          reject(error)
        })
      })
    },
    getGrProductAll ({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.get('/getGrProduct')
          .then(response => {
            commit('setGroupProduct', response.data.d.results)
            resolve(response)
          }, error => {
            commit('setGroupProduct', [])
            reject(error)
          })
      })
    },
    getProductsSearch ({ commit, state, rootState }) {
      return new Promise((resolve, reject) => {
        let $filter = ''
        const $orderby = 'price ASC'
        if (!Vue.prototype.$_.isEmpty(rootState.auth.user.token_id)) {
          $filter += `username eq '${rootState.auth.user.token_id}'`
        }
        if (!Vue.prototype.$_.isEmpty(state.searchKey)) {
          $filter += `and searchKey eq '${state.searchKey}'`
        } else {
          $filter += 'and searchKey eq \'\''
        }

        Vue.prototype.$http.get('/getProducts', {
          params: {
            $filter,
            $orderby,
            $skip: state.paginationSearch.currentPage - 1,
            $top: state.paginationSearch.pageSize
          }
        })
          .then(response => {
            commit('setProductsSearch', [...state.productsSearch, ...response.data.d.results])
            resolve(response)
          }, error => {
            reject(error)
          })
      })
    }
  },
  mutations: {
    setGroupCode: (state, payload) => {
      state.groupCode = payload
    },

    // Pagination
    setPagination: (state, payload) => {
      state.pagination = payload
    },
    setPaginationPageSize: (state, payload) => {
      state.pagination.pageSize = payload
    },
    setPaginationCurrentPage: (state, payload) => {
      state.pagination.currentPage = payload
    },
    setPaginationTotal: (state, payload) => {
      state.pagination.total = payload
    },

    // Pagination Search
    setPaginationSearch: (state, payload) => {
      state.paginationSearch = payload
    },
    setPaginationSearchPageSize: (state, payload) => {
      state.paginationSearch.pageSize = payload
    },
    setPaginationSearchCurrentPage: (state, payload) => {
      state.paginationSearch.currentPage = payload
    },
    setPaginationSearchTotal: (state, payload) => {
      state.paginationSearch.total = payload
    },

    setSortOptionSelected: (state, payload) => {
      state.sortOptionSelected = payload
    },
    setSearchKey: (state, payload) => {
      state.searchKey = payload
    },
    setProductsSearch: (state, payload) => {
      state.productsSearch = payload
    },
    setGroupProduct: (state, payload) => {
      state.groupProduct = payload
    },
    setProducts: (state, payload) => {
      state.products = payload
    }
  }
}
